@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 15s linear infinite;
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
}

.gradient-social {
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-stream-first {
  position: absolute;
  right: 0;
  bottom: -60px;
}

.gradient-stream-second {
  position: absolute;
  z-index: 0;
  left: 0%;
  top: 15%;
}

@media only screen and (max-width: 764px) {
  .gradient {
    position: absolute;
    top: 0;
    right: 0;
  }

  .gradient-social {
    background-image: "../assets/Vector2.svg";
    position: absolute;
    top: 30%;
    left: -120px;
  }

  .gradient-stream-first {
    display: none;
  }

  .gradient-stream-second {
    position: absolute;
    left: -16%;
    top: 57%;
    width: 250px;
    z-index: -10;
  }
}