@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  color: white;
}

@media only screen and (min-width: 600px) {
  .slick-slider {
    margin-left: -35%;
    margin-right: -38%;
  }
}