/* @font-face {
  font-family: "san-medium";
  src: url("./assets//fonts//WEB/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("./assets//fonts//WEB/fonts/Satoshi-Medium.woff") format("woff"),
    url("./assets//fonts//WEB/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
} */

@font-face {
  font-family: "san-bold";
  src: url("./assets/fonts/Satoshi/WEB/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("./assets/fonts/Satoshi/WEB/fonts/Satoshi-Bold.woff") format("woff"),
    url("./assets/fonts/Satoshi/WEB/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: "Satoshi-Variable";
  src: url("./assets/fonts/Satoshi/WEB/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./assets/fonts/Satoshi/WEB/fonts/Satoshi-Variable.woff") format("woff"),
    url("./assets/fonts/Satoshi/WEB/fonts/Satoshi-Variable.ttf") format("truetype");

}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('./assets/fonts/Satoshi/WEB/fonts/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/fonts/Satoshi/WEB/fonts/Satoshi-Regular.woff') format('woff'),
    url('./assets/fonts/Satoshi/WEB/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}



/* @font-face {
  font-family: 'Satoshi-Light';
  src: url('./assets//fonts//WEB/fonts/Satoshi-Light.woff2') format('woff2'),
       url('./assets//fonts//WEB/fonts/Satoshi-Light.woff') format('woff'),
       url('./assets//fonts//WEB/fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
} */

@font-face {
  font-family: 'rubik-light';
  font-weight: 300;
  src: url("./assets/fonts/Rubik/static/Rubik-Light.ttf") format("truetype"),
}



/* .san-medium {
  font-family: san-medium;
} */

.san-bold {
  font-family: san-bold;
}

/* .san-mediumItalic {
  font-family: san-mediumItalic;
} */

.san-variable {
  font-weight: 700;
  font-family: Satoshi-Variable;
  font-display: swap;
  font-style: normal;
}

.san-variable-light {
  font-weight: normal;
  font-family: Satoshi-Variable;
  font-display: swap;
  font-style: normal;
}

/* .san-thin {
  font-family: Satoshi-Light;
}
*/

.san-regular {
  font-family: Satoshi-Regular;
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

.rubik-light {
  font-family: rubik-light;
}